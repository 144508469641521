import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import jsPDF from "jspdf";
import { AC_ADD_COUNTRY, AC_LIST_COUNTRY, AC_DELETE_COUNTRY, AC_VIEW_COUNTRY, AC_RESET_COUNTRY, AC_HANDLE_COUNTRY_CHANGE, AC_UPDATE_COUNTRY, AC_EMPTY_COUNTRY_FEES_SPINNER, AC_COUNTRY_LIST_SPINNER } from '../../../actions/countryAction';
import { Emailvalidate } from '../../../common/validate'
import ImportedURL from '../../../common/api';
import { Error, Success } from '../../../common/swal';
import flagList from './flag.json';
import { Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_CURRENCY } from '../../../actions/currencyAction';
import moment from 'moment-timezone';
import ExportPdfComponent from '../../../utilities/ExportPDF/exportpdf';
import AgGridPiganationComponent from '../../../utilities/AgGridReact/aggridpagination';
import AgGridComponent from '../../../utilities/AgGridReact/aggrid';



const initialState = {
    columnDefs: [
        // { headerName: '#', valueGetter: "node.rowIndex+1", width: 50, filter: false, cellStyle: { 'text-align': 'center' }, sort: { direction: 'asc', priority: 0 }, suppressNavigable: false , hide: 'true'},
        { headerName: 'Country', field: 'name', width: 200, floatingFilter: true, sort: 'asc', sortIndex: 0 },
        { headerName: 'ISO Code', field: 'code', width: 150, floatingFilter: true, cellStyle: { 'text-align': 'center' } },
        // { headerName: 'Country Code', field: 'phonecode', width: 150,cellStyle: {'text-align': 'center'} },
        {
            headerName: 'Country Code', field: 'phonecode', width: 130, filter: false,
            cellRenderer: function (params) {
                if (params.data.flag) {
                    let flag = `<div class=''><i class="country_ctr alignment-flag flag flag-` + params.data.flag + `"></i><span class="pl-2">` + params.data.phonecode + `</span></div>`;
                    return flag
                }
            }
        },
        {
            headerName: 'Currency Symbol', field: 'symbol', width: 130, filter: false, cellStyle: { 'text-align': 'center' },
        },
        {
            headerName: 'Status', width: 85, field: 'status', filter: false, headerClass: 'ag-center-header', suppressMenu: true,
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 150, field: 'actions', headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' }, suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.value) {
                    return params.value;
                } else {
                    return "---"
                }
            }
        },
    ],
    modalType: "Add",
    viewFlag: false,
    nameError: false,
    codeError: false,
    phonecodeError: false,
    flagError: false,
    currencycodeError: false,
    columnApi: '',
    gridApi: '',
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        // minWidth: 150,
        enableSorting: true,
        animateRows: true,
        sortingOrder: ['desc', 'asc']
    },
    // sortingOrder: ['asc', 'desc', null],
    hotelError: false,
    categoryError: false,
    id: '',
    cc: '+1',
    saving: false,
    editSpi: false
}
class ListCountry extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.setState({ modalType: e.target.id, existingowner: false, isowner: false, nameError: false, codeError: false, flagError: false, phonecodeError: false, isowner: false, phonenumberError: false, currencycodeError: false });
        this.props.ResetCountry();
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');


        // if (value === 'View') {
        //     this.props.ResetCountry();
        //     this.setState({ modalType: "View", nameError: false, codeError: false, flagError: false, phonecodeError: false, isowner: false, phonenumberError: false });
        //     this.props.ViewCountry(event.data._id);
        // }
        if (value === 'Edit') {

            this.props.ResetCountry();
            this.setState({ modalType: "Edit", nameError: false, id: event.data._id, codeError: false, flagError: false, phonecodeError: false, isowner: false, phonenumberError: false, editSpi: true, currencycodeError: false });
            this.props.ViewSpinner();
            this.props.ViewCountry(event.data._id);
            this.setState({ ViewSpinner: false })
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.props.DeleteCountry(event.data._id)
                    this.props.ListCountry();
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure you want to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'countries' })
                        .then((data) => {
                            // const dataSource = this.getDataSource();
                            // this.gridApi.setDatasource(dataSource);
                            Success((' Status updated successfully'))
                            this.props.ListCountry();
                        }).catch(({ response }) => {
                            Error(response.statusMessage)
                        });
                }
            })
        }
    }

    onChange = e => {
        this.setState({ ViewSpinner: false })
        const { name, value } = e.target;
        const Error = name + "Error";

        if (name === 'name') {
            this.setState({ name: value });
            var contryName = value;
            if (contryName) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        else if (name === 'code') {
            this.setState({ code: value });
            var countryCode = value;
            if (countryCode) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        else if (name === 'phonecode') {
            this.setState({ phonecode: value });
            var countryPhonecode = value;
            if (countryPhonecode) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
        }
        else if (name === 'cc') {
            this.setState({ cc: value });
        }

        this.setState({ [Error]: false });
        if (name === 'phonecode') {
            const ccData = '+' + value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.props.HandleInputChange(name, ccData);
        } else {
            this.props.HandleInputChange(name, value);
        }
        this.setState({ editSpi: false });
    }
    handleChange = e => {
        this.setState({ ViewSpinner: false })
        const { name, value, label, nameCountry } = e;
        const Error = name + "Error";
        this.setState({ flagError: false, flag: value, viewFlag: value });
        this.props.HandleInputChange("flag", value);
        this.props.HandleInputChange("name", name);
    }
    handleChangeCurrency = e => {
        this.setState({ ViewSpinner: false })
        const { code, name, symbol, symbolnative } = e;
        this.setState({ currencycodeError: false });
        if (e) {
            this.props.HandleInputChange("currencyname", name);
            this.props.HandleInputChange("currencycode", code);
            this.props.HandleInputChange("symbol", symbol);
        }
    }
    submit = () => {
        this.setState({ ViewSpinner: false })
        const { CountryState } = this.props;
        const data = CountryState.country;

        let valid = 1;
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (!data.code) {
            this.setState({ codeError: true });
            valid = 0;
        }
        if (!data.phonecode || data.phonecode == '+') {
            this.setState({ phonecodeError: true });
            valid = 0;
        }
        if (!data.flag) {
            valid = 0;
            this.setState({ flagError: true });
        }
        if (!data.currencycode) {
            valid = 0;
            this.setState({ currencycodeError: true });
        }
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addCountry, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        // Success(res.statusText);
                        Success((' Country created successfully'));
                        this.props.ListCountry();
                        this.props.ResetCountry();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.status == 409) {
                            Error(('This country code already exist'))
                        } else if (response.status == 400) {
                            Error(('Bad request'))
                        }
                    });
            } else {
                axios.post(ImportedURL.API.updateCountry + '/' + this.state.id, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((' Country updated successfully'));
                        // Success(res.statusText);
                        this.props.ListCountry();
                        this.props.ResetCountry();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        // Error(response.statusText)
                        if (response.status == 400) {
                            Error(('Bad request'))
                        }
                    });
            }
        }

    }

    componentDidMount() {
        this.props.ListSpinner();
        this.props.ListCountry();
        this.props.ListCurrency();

        // this.setState({ ViewSpinner: false })
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    searchUser = e => {
        const { name, value } = e;
        this.setState({
            [name]: value,
        });
    }

    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'code', 'phonecode',],
            fileName: 'countries.csv',
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const exportData = this.props.CountryState.listcountry;
        const title = "Countries List";
        const headers = [['#', 'Country', 'ISO Code', 'Country Code', 'Currency Symbol', 'Country Flag']];
        const objName = ['name', 'code', 'phonecode', 'symbol', 'flag'];
        const pdfName = "countries.pdf";
        ExportPdfComponent(exportData, title, headers, objName, pdfName)
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
        } else {
            api.hideOverlay();
        }
    };
    render() {
        const { CountryState, CurrencyState } = this.props;
        const dataCurrency = CurrencyState.ListCurrency;
        const rowData = CountryState.listcountry;
        const data = CountryState.country;
        const ViewSpinner = CountryState.ViewSpinner;
        const ListSpinner = CountryState.ListSpinner;
        const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
        const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
        const template =
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });
        let selectedFlag = '';

        flagList.map(item => {
            if (item.code.toLowerCase() === data.flag) {
                selectedFlag = item.name
            }
        })
        let selectedCurrency = '';
        if (data.currencycode) {
            selectedCurrency = (dataCurrency && dataCurrency.length > 0) && dataCurrency.find(e => e.code === data.currencycode)
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        var timedifference = new Date().getTimezoneOffset();
        var tz = moment.tz.guess();
        let date = new Date()
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-country"><h6 className="highlights_breadcrump"> Countries List </h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-flag mr-2 "></i></div>
                                                <h3 className="card-title"> Countries List</h3>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action">
                                                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </> : []}
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">Limit</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <AgGridComponent spinner={ListSpinner} rowData={rowData} state={this.state} handleFilterChanged={(e) => this.handleFilterChanged(e)} onRowClicked={(e) => this.onRowClicked(e)} onGridReady={(e) => this.onGridReady(e)} />

                                                {/* <div className="table-responsive">
                                                    <div className="ag-theme-alpine">
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={25}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={rowData}
                                                            columnDefs={columnDefs}
                                                            rowDragManaged={true}
                                                            animateRows={true}
                                                            onGridReady={this.onGridReady}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            onFilterChanged={this.handleFilterChanged}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    <div id="overlay" style={{ display: ListSpinner ? 'block' : 'none' }}></div>
                                                    {ListSpinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_model' src='../../assets/images/recycle loader.gif' />
                                                            <Spinner className='spinner_load_model' variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ''}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade country_model" id="exampleModal" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-flag mr-2 "></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} Country  </h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <label className="form-label">Country<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    className="react-select"
                                                    styles={{ border: '1px solid #868686' }}
                                                    isDisabled={this.state.modalType == "View"}
                                                    value={selectedFlag ? { label: selectedFlag.toUpperCase() } : ''}
                                                    onChange={this.handleChange}
                                                    options={flagList.map(item => {
                                                        return { label: `${item.name}`, value: item.code.toLowerCase(), name: item.name, }
                                                    })}
                                                />
                                                <div className="invalid-feedback" style={{ display: this.state.flagError ? "block" : 'none' }}>Country is required</div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                {data.flag ? <i style={{ height: "30px", width: "60px" }} className={'flag_code_sty flag flag-' + data.flag} ></i> : ''}
                                            </div>
                                        </div>
                                        <div className="col-md-5 ">
                                            <div className="form-group">
                                                <label className="form-label">ISO Code<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="text" className="form-control placevalue" maxLength={4} disabled={this.state.modalType == "View"} name='code' onChange={this.onChange} value={data.code.toUpperCase()} placeholder="ISO Code " />
                                                <div className="invalid-feedback" style={{ display: this.state.codeError ? "block" : 'none' }}>ISO Code is required</div>
                                            </div>
                                        </div>
                                        <div className='col-md-7'>
                                            <label className="form-label">Country Code<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <Row>
                                                <div className="col-md-3 pr-1">
                                                    <div className="form-group">
                                                        <select style={{ textAlign: 'center', borderRadius: '17px' }} className="form-control" value={data.cc} disabled={true} onChange={this.onChange} name="cc">
                                                            <option value="+1">+</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-9 pl-0">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control placevalue " maxLength={5} disabled={this.state.modalType == "View"} name='phonecode' onChange={this.onChange} value={data.phonecode.replace("+", "")} placeholder="Country  Code " />
                                                        <div className="invalid-feedback" style={{ display: this.state.phonecodeError ? "block" : 'none' }}>Country Code is required</div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <label className="form-label">Currency Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    className="react-select"
                                                    styles={{ border: '1px solid #868686' }}
                                                    isDisabled={this.state.modalType == "View"}
                                                    value={selectedCurrency && { label: selectedCurrency.name, value: selectedCurrency.code }}
                                                    onChange={this.handleChangeCurrency}
                                                    options={dataCurrency.length > 0 && dataCurrency.map(item => {
                                                        return { label: `${item.name}`, code: item.code, value: item.code, name: item.name, symbol: item.symbol, symbolnative: item.symbolnative }
                                                    })}
                                                />
                                                <div className="invalid-feedback" style={{ display: this.state.currencycodeError ? "block" : 'none' }}>Currency Name is required</div>
                                            </div>
                                        </div>
                                        {
                                            data.symbol &&
                                            <div className="col-md-2">
                                                <div className="form-group" style={{ textAlign: 'center' }}>
                                                    <label className="form-label">Symbol</label>
                                                    <label style={{ fontSize: '21px' }}>{data.symbol}</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {this.state.modalType == "Edit" ?
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div> : <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>}
                                <div id='overlay-model' style={{ display: ViewSpinner ? 'block' : 'none' }}></div>
                                {ViewSpinner ?
                                    <div className='model_loader_poss'>
                                        <img className='loader_img_style_model' src='../../assets/images/recycle loader.gif' />
                                        <Spinner className='spinner_load_model' variant="info" >
                                        </Spinner>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,

    CountryState: state.country,
    CurrencyState: state.currency,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_COUNTRY_CHANGE,
        AddCountry: AC_ADD_COUNTRY,
        ListCurrency: AC_LIST_CURRENCY,
        ListCountry: AC_LIST_COUNTRY,
        ViewCountry: AC_VIEW_COUNTRY,
        ResetCountry: AC_RESET_COUNTRY,
        UpdateCountry: AC_UPDATE_COUNTRY,
        DeleteCountry: AC_DELETE_COUNTRY,
        ViewSpinner: AC_EMPTY_COUNTRY_FEES_SPINNER,
        ListSpinner: AC_COUNTRY_LIST_SPINNER
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListCountry);

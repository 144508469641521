const initialState = {
    listcategories: [],
    totelcategories:0,
    categories: {
        categoryname:'',
        subcategoryname: '',
        image: '',
        categories:"",
        quantity:'',
        marketprice:'',
        
    },
    ViewSpinner: false,
    ListSpinner: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_CATEGOIRES_CHANGE':
            return Object.assign({}, state, {
                categories: {
                    ...state.categories,
                    [action.name]: action.value
                }
            })
            case 'RESET_CATEGOIRES_DETAIL':
                return Object.assign({}, state, {
                    categories: initialState.categories,
                })
                case 'EMPTY_CATEGOIRES_FEES_SPINNER':
                    return Object.assign({}, state, {
                        ViewSpinner: true
                    })
                    case 'CATEGOIRES_LIST_SPINNER':
                        return Object.assign({}, state, {
                            ListSpinner: true
                        })
            case 'LIST_CATEGOIRES_DETAIL':
                return {
                    ...state,
                    listcategories: action.payload,
                    totelcategories: action.total,
                    ListSpinner: false,
                }
            case 'VIEW_CATEGOIRES_DETAIL':
                return Object.assign({}, state, {
                    categories: action.payload,
                    ViewSpinner: false,
                })
    
            case 'EMPTY_CATEGOIRES_DETAIL':
                return Object.assign({}, state, {
                    categories: {
                        categoryname:'',
                        subcategoryname: '',
                        image: '',
                        categories:"",
                        quantity:'',
                        marketprice:'',
                        
                    },
                })
        
        default:
            return state;
    }
}
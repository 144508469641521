import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AggridSpinnerComponent from '../Spinner/aggridspinner';

class AgGridPiganationComponent extends Component {
    render() {
        const { spinner, rowData, onRowClicked, onGridReady, state } = this.props;
        const { perPage, defaultColumDef, columnDefs } = state;
        return (
            <>
                <div className="table-responsive">
                    <div className={`${(rowData && rowData.length == 0) ? "ag_grid_no_record_show" : ""} ag-theme-alpine`} style={{ textAlign: 'left' }}>
                        <AgGridReact
                            rowHeight={82}
                            paginationPageSize={perPage}
                            cacheBlockSize={perPage}
                            pagination={true}
                            onRowClicked={(e) => onRowClicked(e)}
                            domLayout={"autoHeight"}
                            defaultColDef={defaultColumDef}
                            onGridReady={(e) => onGridReady(e)}
                            columnDefs={columnDefs}
                            rowDragManaged={true}
                            wrapText={true}
                            animateRows={true}
                            overlayNoRowsTemplate={"No rows to display"}
                            rowModelType={'infinite'}
                        >
                        </AgGridReact>
                    </div>
                    {
                        (rowData && rowData.length == 0)
                        &&
                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                    }
                    <AggridSpinnerComponent spinner={spinner} />
                </div>
            </>
        )
    }
}
export default AgGridPiganationComponent
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_COUNTRY_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_COUNTRY_CHANGE", name: name, value: value })
	};
}

export function AC_ADD_COUNTRY(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.addCountry, formData)
			.then((res) => {
				Success('Country created successfully');
				// Success(res.statusText);
				dispatch({ type: "LIST_COUNTRY", payload: res.data })
			}).catch(({ response }) => {
				if (response.status == 409) {
					Error('This country code already exist')
				} else if (response.status == 400) {
					Error('Bad request')
				}
			});
	};
}

export function AC_LIST_COUNTRY(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listCountry, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_COUNTRY", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_COUNTRY_CATEGORY() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listCountryCategory)
			.then((res) => {
				dispatch({ type: "LIST_COUNTRY", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_COUNTRY(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewCountry + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_COUNTRY", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_COUNTRY(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateCountry + "/" + id, formData)
			.then((res) => {
				Success('Country updated successfully');
				// Success(res.statusText);
				dispatch({ type: "LIST_COUNTRY", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_DELETE_COUNTRY(id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.deleteCountry + "/" + id)
			.then((res) => {
				Success('Country deleted successfully');
				// Success(res.statusText);
				dispatch({ type: "LIST_COUNTRY", payload: res.data })
			}).catch(({ response }) => {
				if (response.status == 400) {
					Error('Bad request')
				}
			});
	};
}

export function AC_RESET_COUNTRY() {
	return function (dispatch) {
		dispatch({ type: "RESET_COUNTRY" })
	};
}

export function AC_EMPTY_COUNTRY_FEES_SPINNER() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_COUNTRY_FEES_SPINNER" })
    }
}
export function AC_COUNTRY_LIST_SPINNER() {
    return function (dispatch) {
        dispatch({ type: "COUNTRY_LIST_SPINNER" })
    }
}
import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_USERCATEGORY_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_USERCATEGORY_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_USERCATEGORY_DETAIL() {
    return function (dispatch) {
        dispatch({ type: "RESET_USERCATEGORY_DETAIL" })
    };
}
export function AC_EMPTY_USERCATEGORY_FEES_SPINNER() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_CATEGORY_FEES_SPINNER" })
    }
}
export function AC_USERCATEGORY_LIST_SPINNER() {
    return function (dispatch) {
        dispatch({ type: "CATEGORY_LIST_SPINNER" })
    }
}
export function AC_LIST_USERCATEGORY_DETAIL(params = {}) {
    return  function (dispatch) {
        return (
             axios.post(ImportedURL.API.listUser,params)
                .then((res) => {
                    dispatch({ type: "LIST_USERCATEGORY_DETAIL", payload: res.data.data, total: res.data.total, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_USERCATEGORY_DETAIL(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewUser + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_USERCATEGORY_DETAIL", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


export function AC_EMPTY_USERCATEGORY_DETAIL() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_USERCATEGORY_DETAIL" })
    }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import URL from '../../common/api';
import { Success, Error } from '../../common/swal';
import { Emailvalidate, onErrorImage } from '../../common/validate';
import ErrorResponseComponent from '../../utilities/ErrorStatus/errorresponse';

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			emailError: false,
			emailValidError: false,
			passwordError: false,
		}
	}
	onChange = e => {
		const { name, value } = e.target;
		const Error = name + "Error";
		const ValidError = name + "ValidError";
		this.setState({ [name]: value })
		if (name === 'email') {
			this.setState({ email: value });
			var email = value;
			if (email) {
				this.setState({ [Error]: false });
				if (Emailvalidate(email)) {
					this.setState({ [ValidError]: false, [Error]: false })
				} else {
					this.setState({ [ValidError]: true })
				}
			}
			else {
				this.setState({ emailError: true, [ValidError]: false });
			}
		}
	}
	submit = (e) => {
		e.preventDefault();
		const { email, password } = this.state;
		if (!email) this.setState({ emailError: true });
		if (!password) this.setState({ passwordError: true });
		if (email && password) {
			this.setState({ saving: true });
			const formData = { email: email.toLowerCase(), password: password }
			axios.post(URL.API.login, formData)
				.then((res) => {
					this.setState({ saving: false });
					Success("Logged in successfully");
					localStorage.setItem('wastemanagement', res.data);
					window.location.href = "/";
				}).catch(({ response }) => {
					this.setState({ saving: false });
					ErrorResponseComponent(response);
				});
		}
	}
	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center mb-2" style={{ width: '100px', height: '100px', margin: ' 0 auto' }}>
							<Link className="header-brand" to="/">
								<img className="avatar w-100 login_logo" src="../assets/images/newlogo.png" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ height: '100%', objectFit: 'cover' }} onError={onErrorImage} />
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title">Login to your account</div>
							<div className="form-group">
								<label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
								<input
									type="email"
									className="form-control"
									name="email"
									aria-describedby="emailHelp"
									placeholder="Enter email"
									onChange={this.onChange}
								/>
								<div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
								<div className="invalid-feedback" style={{ display: this.state.emailValidError ? 'block' : 'none' }}>Enter valid email</div>
							</div>
							<div className='pass_forgot'>
								<label className="form-label">Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
								<span>
									<Link className="Forgot_Password float-right small" to="/forgotpassword">
										Forgot Password?
									</Link>
								</span>
							</div>
							<div className="form-group">
								<input
									type="password"
									className="form-control"
									name="password"
									placeholder="Password"
									onChange={this.onChange}
								/>
								<div className="invalid-feedback" style={{ display: this.state.passwordError ? "block" : 'none' }}>Password is required</div>
							</div>
							<div className="form-footer">
								{this.state.saving ?
									<a className="btn login_btn btn-block" href="/" onClick={this.submit}>
										<i className="fa fa-spinner fa-spin mr-2"></i>login
									</a>
									:
									<a className="btn login_btn btn-block" href="/" onClick={this.submit} >
										<i className="fa fa-sign-in mr-2"></i>login
									</a>
								}
							</div>
						</div>


						{/* <div className="text-center text-muted">
							Don't have account yet? <Link to="/signup">Sign Up</Link>
						</div> */}
					</div>
				</div>
				<div className="auth_right">
					<div className="carousel slide" data-ride="carousel" data-interval={3000}>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src="assets/images/carousel/carousel1.png" className="img-fluid" alt="login page" onError={onErrorImage} />
								<div className="px-4 mt-4">
									<h4>Polythene Waste</h4>
									<p>Polythene waste is non-biodegradable plastic contributing to environmental pollution.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/carousel/carousel2.png" className="img-fluid" alt="login page" onError={onErrorImage} />
								<div className="px-4 mt-4">
									<h4>Tetra Pack Waste Packets</h4>
									<p>Managing discarded Tetra Pak packets for environmental sustainability.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/carousel/carousel3.png" className="img-fluid" alt="login page" onError={onErrorImage} />
								<div className="px-4 mt-4">
									<h4>Plastic Waste</h4>
									<p>Plastic waste is a pervasive environmental issue with long-lasting impacts on ecosystems.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/carousel/carousel4.png" className="img-fluid" alt="login page" onError={onErrorImage} />
								<div className="px-4 mt-4">
									<h4>Paper Waste</h4>
									<p>Paper waste is discarded or unused paper materials.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/carousel/carousel5.png" className="img-fluid" alt="login page" onError={onErrorImage} />
								<div className="px-4 mt-4">
									<h4>E-Waste</h4>
									<p>E-waste refers to discarded electronic devices and equipment, posing environmental challenges due to hazardous materials and improper disposal.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import DefaultLink from './DefaultLink';

import {
	darkModeAction, darkHeaderAction, fixNavbarAction,
	darkMinSidebarAction, darkSidebarAction, iconColorAction,
	gradientColorAction, rtlAction, fontAction,
	subMenuIconAction,
	menuIconAction,
	boxLayoutAction,
	statisticsAction, friendListAction,
	statisticsCloseAction, friendListCloseAction, toggleLeftMenuAction
} from '../../actions/settingsAction';
import Path from '../Route';
import maincategory from '../maincategory/maincategory';
import ListSubCategory from '../Subcategory/listsubcategory';
import categories from '../categories/categories';
import Login from '../Authentication/login';
import SignUp from '../Authentication/signup';
import ChangePassword from "../Settings/changepassword";
import SiteSettings from '../Settings/sitesettings';
import ForgotPassword from '../Authentication/forgotpassword';
import NotFound from '../Authentication/404';
import InternalServer from '../Authentication/500';
import dashboard from '../Dashboard/dashboard.jsx';
import ListCurrency from "../master/currency/listcurrency";
import ListCountry from "../master/country/listcountry";
import viewcategories from '../categories/viewcategories';
import post from '../post/post';
import viewpost from '../post/viewpost';
import Sellingrequest from '../selling request/selling request';
import viewsellingrequest from '../selling request/viewselling';
import listUsers from "../Users/listusers";
import viewusers from '../Users/viewusers';



const masterNone = {
	display: 'none',
};

const masterBlock = {
	display: 'block',
};

const MissingRoute = () => {
	return <Redirect to='/notFound' />;
}

class Menu extends Component {
	constructor(props) {
		super(props);
		this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
		this.toggleUserMenu = this.toggleUserMenu.bind(this);
		this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
		this.toggleSubMenu = this.toggleSubMenu.bind(this);
		this.handleDarkMode = this.handleDarkMode.bind(this);
		this.handleFixNavbar = this.handleFixNavbar.bind(this);
		this.handleDarkHeader = this.handleDarkHeader.bind(this);
		this.handleMinSidebar = this.handleMinSidebar.bind(this);
		this.handleSidebar = this.handleSidebar.bind(this);
		this.handleIconColor = this.handleIconColor.bind(this);
		this.handleGradientColor = this.handleGradientColor.bind(this);
		this.handleRtl = this.handleRtl.bind(this);
		this.handleFont = this.handleFont.bind(this);
		this.handleStatistics = this.handleStatistics.bind(this);
		this.handleFriendList = this.handleFriendList.bind(this);
		this.closeFriendList = this.closeFriendList.bind(this);
		this.closeStatistics = this.closeStatistics.bind(this);
		this.handleBoxLayout = this.handleBoxLayout.bind(this);
		this.handler = this.handler.bind(this);
		this.state = {
			isToggleLeftMenu: false,
			isOpenUserMenu: false,
			isOpenRightSidebar: false,
			isBoxLayout: false,
			parentlink: null,
			childlink: null,
		};
		this.toggleVisible = this.toggleVisible.bind(this);
	}

	componentDidMount() {
		const { location } = this.props;
		const links = location.pathname.substring(1).split(/-(.+)/);
		const parentlink = links[0];
		const nochildlink = links[1];
		if (parentlink && nochildlink && nochildlink === 'dashboard') {
			this.handler(parentlink, `${parentlink}${nochildlink}`);
		} else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
			this.handler(parentlink, nochildlink);
		} else if (parentlink) {
			this.handler(parentlink, '');
		} else {
			this.handler('hr', 'dashboard');
		}
		window.addEventListener('scroll', this.toggleVisible);
	}

	componentDidUpdate(prevprops, prevstate) {
		const { location } = this.props;
		const links = location.pathname.substring(1).split(/-(.+)/);
		const parentlink = links[0];
		const nochildlink = links[1];
		if (prevprops.location !== location) {
			if (parentlink && nochildlink && nochildlink === 'dashboard') {
				this.handler(parentlink, `${parentlink}${nochildlink}`);
			} else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
				this.handler(parentlink, nochildlink);
			} else if (parentlink) {
				this.handler(parentlink, '');
			} else {
				this.handler('hr', 'dashboard');
			}
		}
	}

	handler(parentlink, nochildlink) {
		this.setState({
			parentlink: parentlink,
			childlink: nochildlink,
		});
	}

	handleDarkMode(e) {
		this.props.darkModeAction(e.target.checked)
	}
	handleFixNavbar(e) {
		this.props.fixNavbarAction(e.target.checked)
	}
	handleDarkHeader(e) {
		this.props.darkHeaderAction(e.target.checked)
	}
	handleMinSidebar(e) {
		this.props.darkMinSidebarAction(e.target.checked)
	}
	handleSidebar(e) {
		this.props.darkSidebarAction(e.target.checked)
	}
	handleIconColor(e) {
		this.props.iconColorAction(e.target.checked)
	}
	handleGradientColor(e) {
		this.props.gradientColorAction(e.target.checked)
	}
	handleRtl(e) {
		this.props.rtlAction(e.target.checked)
	}
	handleFont(e) {
		this.props.fontAction(e)
	}
	handleFriendList(e) {
		this.props.friendListAction(e)
	}
	handleStatistics(e) {
		this.props.statisticsAction(e)
	}
	closeFriendList(e) {
		this.props.friendListCloseAction(e)
	}
	closeStatistics(e) {
		this.props.statisticsCloseAction(e)
	}
	handleSubMenuIcon(e) {
		this.props.subMenuIconAction(e)
	}
	handleMenuIcon(e) {
		this.props.menuIconAction(e)
	}
	handleBoxLayout(e) {
		this.props.boxLayoutAction(e.target.checked)
	}
	toggleLeftMenu(e) {
		this.props.toggleLeftMenuAction(e)
	}
	toggleRightSidebar() {
		this.setState({ isOpenRightSidebar: !this.state.isOpenRightSidebar })
	}
	toggleUserMenu() {
		this.setState({ isOpenUserMenu: !this.state.isOpenUserMenu })
	}
	toggleSubMenu(e) {
		let menucClass = ''
		if (e.itemId) {
			const subClass = e.items.map((menuItem, i) => {
				if (menuItem.to === this.props.location.pathname) {
					menucClass = "in";
				} else {
					menucClass = "collapse";
				}
				return menucClass
			})
			return subClass
			// return "collapse";
		} else {
			return e.visible ? "collapse" : "metismenu";
		}
	}
	getPrevilege = (name) => {
		const { AccountState } = this.props;
		const previleges = AccountState.previleges;
		const Previlege = previleges ? previleges.find(obj => { return obj.name == name }) : {};
		return Previlege ? Previlege : {};
	}
	getRoutes = () => {
		const Routes = [
			{
				path: "/login",
				name: 'login',
				exact: true,
				pageTitle: "Tables",
				component: Login
			},
			{
				path: "/signup",
				name: 'signup',
				exact: true,
				pageTitle: "Tables",
				component: SignUp
			},
			{
				path: "/forgotpassword",
				name: 'forgotpassword',
				exact: true,
				pageTitle: "Tables",
				component: ForgotPassword
			},
			{
				path: "/notfound",
				name: 'notfound',
				exact: true,
				pageTitle: "Tables",
				component: NotFound
			},
			{
				path: "/internalserver",
				name: 'internalserver',
				exact: true,
				pageTitle: "Tables",
				component: InternalServer
			},
			{
				path: "/",
				name: 'Dashboard',
				exact: true,
				pageTitle: "Dashboard",
				component: dashboard
			},
			{
				path: "/site-settings",
				name: 'Site Settings',
				exact: true,
				pageTitle: "Site Settings",
				component: SiteSettings
			},
			{
				path: "/change-password",
				name: 'change password',
				exact: true,
				pageTitle: "change password",
				component: ChangePassword
			},
		]

		const { AccountState } = this.props;
		const role = AccountState.role;
		const categoryType = AccountState.account.categoryType;

		let MainCategory = this.getPrevilege('MainCategory');
		if (MainCategory.view) {
			Routes.push(
				{
					path: "/list-maincategory",
					name: 'list-maincategory',
					exact: true,
					pageTitle: "report",
					component: maincategory
				}
			)
		}
		let SubCategory = this.getPrevilege('SubCategory');
		if (SubCategory.view) {
			Routes.push(
				{
					path: "/list-subcategory",
					name: 'Sub Category',
					exact: true,
					pageTitle: "SubCategory",
					component: ListSubCategory
				}
			)
		}
		let Categories = this.getPrevilege('Categories');
		if (Categories.view) Routes.push(
			{
				path: "/list-categories",
				name: 'Categories',
				exact: true,
				pageTitle: "Categories",
				component: categories
			},
			{
				path: "/view-categories/:id",
				name: 'Categories',
				exact: true,
				pageTitle: "Viewcategories",
				component: viewcategories
			}
		)
		// =====post===
		let Post = this.getPrevilege("Post");
		if (Post.view) Routes.push(
			{
				path: "/list-Post",
				name: 'list Post',
				exact: true,
				pageTitle: "list Post",
				component: post
			},
			{
				path: "/View-Post/:id",
				name: 'View Post',
				exact: true,
				pageTitle: "View Post",
				component: viewpost
			}
		)
		//===========selling request========//
		let sellingrequest = this.getPrevilege('Sellingrequest');
		if (sellingrequest.view) Routes.push(
			{
				path: "/list-sellingrequest",
				name: "list Sellingrequest",
				exact: true,
				pageTitle: "list Sellingrequest",
				component: Sellingrequest
			},
			{
				path: "/view-sellingrequest/:id",
				name: 'sellingrequest',
				exact: true,
				pageTitle: "sellingrequest",
				component: viewsellingrequest
			}
		)
		// =========== users ======
		let Users = this.getPrevilege("Users");
		if (Users.view) Routes.push(
			{
				path: "/users-list",
				name: 'User',
				exact: true,
				pageTitle: "User",
				component: listUsers
			},
			{
				path: "/view-users/:id",
				name: 'User',
				exact: true,
				pageTitle: "User",
				component: viewusers
			}
		)
		let Country = this.getPrevilege('Countries');
		if (Country.view) {
			Routes.push(
				{
					path: "/list-country",
					name: 'List Country',
					exact: true,
					pageTitle: "List Country",
					component: ListCountry
				}
			)
		}
		let Currecny = this.getPrevilege('Currencies');
		if (Currecny.view) {
			Routes.push(
				{
					path: "/list-currency",
					name: 'List currency',
					exact: true,
					pageTitle: "List currency",
					component: ListCurrency
				}
			)
		}


		return Routes;
	}
	getContent = () => {
		const { AccountState } = this.props;
		const account = AccountState?.account;
		const SelectedlanguageData = AccountState.account.SelectedlanguageData;
		const role = AccountState.role;
		const categoryType = AccountState.account.categoryType;
		let unique = 0;
		let content = [
			{
				"id": 'SiteManagement',
				"label": "Site Management"
			},
		];

		content.push(
			{
				"id": unique++,
				"label": "Dashboard",
				"icon": "fa fa-area-chart",
				"to": "/"
			}
		)

		// ==========Categories=======
		let innnercontentcategory = [];
		let linkToCategory = '';
		let nameCategory = '';
		let iconCategory = '';

		if (this.getPrevilege('MainCategory').view) {
			innnercontentcategory.push(
				{
					"id": unique++,
					"label": "Main Category",
					"to": "/list-maincategory"
				}
			)
			linkToCategory = "/list-maincategory"
			nameCategory = "Main Category"
			iconCategory = "fa fa-cubes"
		}

		if (this.getPrevilege('SubCategory').view) {
			innnercontentcategory.push(
				{
					"id": unique++,
					"label": "Sub Category",
					"to": "/list-subcategory"
				}
			)
			linkToCategory = "/list-subcategory"
			nameCategory = "Sub Category"
			iconCategory = "fa fa-cubes"
		}

		if (this.getPrevilege('Categories').view) {
			innnercontentcategory.push(
				{
					"id": unique++,
					"label": "Categories",
					"to": "/list-categories"
				}
			)
			linkToCategory = "/list-categories"
			nameCategory = " Categories"
			iconCategory = "fa fa-cubes"
		}

		if (innnercontentcategory.length == 1) {
			content.push({
				"id": unique++,
				"icon": iconCategory,
				"label": nameCategory,
				"to": linkToCategory,
			})

		} else if (innnercontentcategory.length) {
			content.push(
				{
					"id": unique++,
					"icon": "fa fa-cubes",
					"label": "Categories",
					content: innnercontentcategory,
				}
			)
		}

		// --------------post management--------
		if (this.getPrevilege('Post').view) content.push(
			{
				"id": unique++,
				"icon": "fa fa-envelope",
				"label": "Create Post",
				"to": "/list-Post",
			}
		)

		//===============selling request=============//
		if (this.getPrevilege('Sellingrequest').view) content.push(
			{
				"id": unique++,
				"icon": "fa fa-shopping-cart",
				"label": "Selling Request",
				"to": "/list-sellingrequest",
			}
		)
		//   =============users============
		if (this.getPrevilege('Users').view) content.push(
			{
				"id": unique++,
				"icon": "fa fa-users",
				"label": "Users",
				"to": "/users-list",
			}
		)
		// content.push(
		// 	{
		// 		"id": 6,
		// 		"icon": "fa fa-users",
		// 		"label": " Users",
		// 		"to": "/users-list"
		// 	},
		// )
		// =========== Master=======
		let innnercontentmaster = [];
		let linkToMaster = '';
		let nameMaster = '';
		let iconMaster = '';

		if (this.getPrevilege('Countries').view) {
			innnercontentmaster.push(
				{
					"id": unique++,
					"label": "Countries",
					"to": "/list-country"
				}
			)
			linkToMaster = "/list-country"
			nameMaster = "Countries"
			iconMaster = "fa fa-cubes"
		}

		if (this.getPrevilege('Currencies').view) {
			innnercontentmaster.push(
				{
					"id": unique++,
					"label": "Currencies",
					"to": "/list-currency"
				}
			)
			linkToMaster = "/list-currency"
			nameMaster = "Currencies"
			iconMaster = "fa fa-cubes"
		}

		if (innnercontentmaster.length == 1) {
			content.push({
				"id": unique++,
				"icon": iconMaster,
				"label": nameMaster,
				"to": linkToMaster,
			})

		} else if (innnercontentmaster.length) {
			content.push(
				{
					"id": unique++,
					"icon": "fa fa-table",
					"label": "Master",
					content: innnercontentmaster,
				}
			)
		}
		// ============settings====

		let innnercontentsettings = [];
		let linkToSettings = '';
		let nameSettings = '';
		let iconSettings = '';


		innnercontentsettings.push(
			{
				"id": unique++,
				"label": "Site Settings",
				"to": "/site-settings"
			}
		)
		linkToSettings = "/site-settings"
		nameSettings = "Site Settings"
		iconSettings = "fa fa-cubes"



		innnercontentsettings.push(
			{
				"id": unique++,
				"label": "Change password",
				"to": "/change-password"
			}
		)
		linkToSettings = "/change-password"
		nameSettings = "Change password"
		iconSettings = "fa fa-cubes"


		if (innnercontentsettings.length == 1) {
			content.push({
				"id": unique++,
				"icon": iconSettings,
				"label": nameSettings,
				"to": linkToSettings,
			})

		} else if (innnercontentsettings.length) {
			content.push(
				{
					"id": unique++,
					"icon": "fa fa-gear",
					"label": "Settings",
					content: innnercontentsettings,
				}
			)
		}



		return content
	}
	toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			this.setState({ isTopToggle: true })
		}
		else if (scrolled <= 300) {
			this.setState({ isTopToggle: false })
		}
	};

	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
			/* you can also use 'auto' behaviour
			   in place of 'smooth' */
		});
	};

	render() {

		const { AccountState } = this.props;
		const role = AccountState.account ? AccountState.account.role : '';
		const previleges = AccountState.previleges;
		let content = this.getContent();
		let Routes = this.getRoutes();
		const { isOpenRightSidebar } = this.state;
		const { darkMinSidebar, istoggleLeftMenu, } = this.props
		const pageHeading = Routes.filter((route) => route.path === this.props.location.pathname)
		const adminName = AccountState.account.name;
		return (
			<>
				<div className={`${istoggleLeftMenu ? "offcanvas-active" : ""}`}>
					<div style={this.state.parentlink === 'login' ? masterNone : masterBlock}>
						<div id="header_top" className={`header_top ${!darkMinSidebar && 'dark'}`}>
							{/* <div id="header_top" className="header_top dark"> */}
							<div className="container">
								<div className="hleft">
									<NavLink
										to="/"
										onClick={() => this.handler('hr', 'dashboard')}
										className="header-brand"
									>
										<img class="avatar custom-sidebar-logo" src="../assets/images/newlogo.png" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" />
									</NavLink>
								</div>
								<div className="hright">
									<div className="dropdown">
										<span className="nav-link icon settingbar" onClick={this.toggleRightSidebar}>
											<i
												className="fa fa-gear fa-spin"
												data-toggle="tooltip"
												data-placement="right"
												title="Settings"
											/>
										</span>
										<p className="nav-link icon menu_toggle" onClick={() => this.toggleLeftMenu(!istoggleLeftMenu)}>
											<i className="fa  fa-align-left" />
										</p>
									</div>
								</div>
							</div>
						</div>
						<div id="rightsidebar" className={`right_sidebar ${isOpenRightSidebar && "open"}`}>
							<span className="p-3 settingbar float-right" onClick={this.toggleRightSidebar}>
								<i className="fa fa-close" />
							</span>
							<ul className="nav nav-tabs" role="tablist">
								<li className="nav-item">
									<a className="nav-link active" data-toggle="tab" href="#Settings" aria-expanded="true">
										Settings
									</a>
								</li>
							</ul>
							<div className="tab-content">
								<div
									role="tabpanel"
									className="tab-pane vivify fadeIn active"
									id="Settings"
									aria-expanded="true"
								>
									<div className="mb-4">
										<h6 className="font-14 font-weight-bold text-muted">Font Style</h6>
										<div className="custom-controls-stacked font_setting">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													defaultChecked
													defaultValue="font-opensans"
													onChange={() => this.handleFont('font-opensans')}
												/>
												<span className="custom-control-label">Open Sans Font</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													defaultValue="font-montserrat"
													onChange={() => this.handleFont('font-montserrat')}
												/>
												<span className="custom-control-label">Montserrat Google Font</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="font"
													onChange={() => this.handleFont('font-roboto')}
												/>
												<span className="custom-control-label">Robot Google Font</span>
											</label>
										</div>
									</div>
									<div className="mb-4">
										<h6 className="font-14 font-weight-bold text-muted">Selected Menu Icon</h6>
										<div className="custom-controls-stacked arrow_option">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-a"
													onChange={() => this.handleMenuIcon('list-a')}
												/>
												<span className="custom-control-label">A</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-b"
													onChange={() => this.handleMenuIcon('list-b')}
												/>
												<span className="custom-control-label">B</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="marrow"
													defaultValue="arrow-c"
													defaultChecked
													onChange={() => this.handleMenuIcon('list-c')}
												/>
												<span className="custom-control-label">C</span>
											</label>
										</div>

										<h6 className="font-14 font-weight-bold mt-4 text-muted">SubMenu List Icon</h6>
										<div className="custom-controls-stacked list_option">
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-a"
													defaultChecked
													onChange={() => this.handleSubMenuIcon('list-a')}
												/>
												<span className="custom-control-label">A</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-b"
													onChange={() => this.handleSubMenuIcon('list-b')}
												/>
												<span className="custom-control-label">B</span>
											</label>
											<label className="custom-control custom-radio custom-control-inline">
												<input
													type="radio"
													className="custom-control-input"
													name="listicon"
													defaultValue="list-c"
													onChange={() => this.handleSubMenuIcon('list-c')}
												/>
												<span className="custom-control-label">C</span>
											</label>
										</div>
									</div>
									<div>
										<h6 className="font-14 font-weight-bold mt-4 text-muted">General Settings</h6>
										<ul className="setting-list list-unstyled mt-1 setting_switch">
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Night Mode</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-darkmode"
														onChange={(e) => this.handleDarkMode(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Fix Navbar top</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-fixnavbar"
														onChange={(e) => this.handleFixNavbar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Header Dark</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-pageheader"
														onChange={(e) => this.handleDarkHeader(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Min Sidebar Light</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-min_sidebar"
														onChange={(e) => this.handleMinSidebar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Sidebar Dark</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-sidebar"
														onChange={(e) => this.handleSidebar(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Icon Color</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-iconcolor"
														onChange={(e) => this.handleIconColor(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>
											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">Gradient Color</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-gradient"
														onChange={(e) => this.handleGradientColor(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>

											<li>
												<label className="custom-switch">
													<span className="custom-switch-description">RTL Support</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-rtl"
														onChange={(e) => this.handleRtl(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li>

										</ul>
									</div>
									<hr />
								</div>
							</div>
						</div>
						<div className="theme_div">
							<div className="card">
								<div className="card-body">
									<ul className="list-group list-unstyled">
										<li className="list-group-item mb-2">
											<p>Default Theme</p>
											<a href="../main/index.html">
												<img
													src="/assets/images/themes/default.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Night Mode Theme</p>
											<a href="../dark/index.html">
												<img
													src="/assets/images/themes/dark.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>RTL Version</p>
											<a href="../rtl/index.html">
												<img
													src="/assets/images/themes/rtl.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version2</p>
											<a href="../theme2/index.html">
												<img
													src="/assets/images/themes/theme2.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version3</p>
											<a href="../theme3/index.html">
												<img
													src="/assets/images/themes/theme3.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version4</p>
											<a href="../theme4/index.html">
												<img
													src="/assets/images/themes/theme4.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Horizontal Version</p>
											<a href="../horizontal/index.html">
												<img
													src="/assets/images/themes/horizontal.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div id="left-sidebar" className="sidebar ">
							<h5 className="brand-name">KOKANNEST <h6>WASTE MANAGEMENT</h6></h5>
							<h6 className="brand-name siteNameAdmin">{adminName}</h6>
							<nav id="left-sidebar-nav" className="sidebar-nav">
								<MetisMenu className=""
									content={content}
									noBuiltInClassNames={true}
									classNameContainer={(e) => this.toggleSubMenu(e)}
									classNameContainerVisible="in"
									classNameItemActive="active"
									classNameLinkActive="active"
									classNameItemHasVisibleChild="active"
									classNameLink="has-arrow arrow-c"
									iconNameStateHidden="fa fa-angle-down"
									iconNameStateVisible="fa fa-angle-down"
									classNameStateIcon='right-allign-menu'
									classNameItem="ul-class"
									iconNamePrefix=""
									LinkComponent={(e) => <DefaultLink itemProps={e} />}
								/>
							</nav>
						</div>
					</div>

					<div className="page" style={this.state.parentlink == 'login' ? { left: '0px', width: 'calc(100% - 0px)' } : { width: 'calc(100% - 280px)' }}>
						{this.state.parentlink === 'login' ? '' :
							<Header dataFromParent={this.props.dataFromParent} dataFromSubParent={pageHeading[0] ? pageHeading[0].pageTitle : ''} />
						}
						<Switch>
							{Routes.map((layout, i) => {
								return <Route key={i} exact={layout.exact} path={layout.path} component={layout.component}></Route>
							})}
							{previleges && previleges.length ? <Route path="*" component={MissingRoute} /> : ''}
						</Switch>
						<Footer />

					</div>
				</div>
				<>
					<div className='scrool_top_button' style={{ display: this.state.isTopToggle ? 'block' : 'none' }}>
						<button type='button' className='top_icon' onClick={this.scrollToTop}>
							<i class="fa fa-arrow-up" data-toggle="tooltip" title="fa fa-arrow-up"></i></button>
					</div>
				</>
			</>
		);
	}
}

const mapStateToProps = state => ({
	darkMinSidebar: state.settings.isMinSidebar,
	statisticsOpen: state.settings.isStatistics,
	friendListOpen: state.settings.isFriendList,
	statisticsClose: state.settings.isStatisticsClose,
	friendListClose: state.settings.isFriendListClose,
	istoggleLeftMenu: state.settings.isToggleLeftMenu,
	AccountState: state.account,
})

const mapDispatchToProps = dispatch => ({
	darkModeAction: (e) => dispatch(darkModeAction(e)),
	darkHeaderAction: (e) => dispatch(darkHeaderAction(e)),
	fixNavbarAction: (e) => dispatch(fixNavbarAction(e)),
	darkMinSidebarAction: (e) => dispatch(darkMinSidebarAction(e)),
	darkSidebarAction: (e) => dispatch(darkSidebarAction(e)),
	iconColorAction: (e) => dispatch(iconColorAction(e)),
	gradientColorAction: (e) => dispatch(gradientColorAction(e)),
	rtlAction: (e) => dispatch(rtlAction(e)),
	fontAction: (e) => dispatch(fontAction(e)),
	subMenuIconAction: (e) => dispatch(subMenuIconAction(e)),
	menuIconAction: (e) => dispatch(menuIconAction(e)),
	boxLayoutAction: (e) => dispatch(boxLayoutAction(e)),
	statisticsAction: (e) => dispatch(statisticsAction(e)),
	friendListAction: (e) => dispatch(friendListAction(e)),
	statisticsCloseAction: (e) => dispatch(statisticsCloseAction(e)),
	friendListCloseAction: (e) => dispatch(friendListCloseAction(e)),
	toggleLeftMenuAction: (e) => dispatch(toggleLeftMenuAction(e))
})
export default connect(mapStateToProps, mapDispatchToProps)(Menu);

const initialState = {
    country: {
        name: '',
        status: 1,
        code: '',
        phonecode: '',
        flag: '',
        currencyname: '',
        currencycode: '',
        symbol: '',
        id: ''
    },
    listcountry: [],
    total:'',
    ViewSpinner: false,
    ListSpinner: false,
    total: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_COUNTRY_CHANGE':
            return Object.assign({}, state, {
                country: {
                    ...state.country,
                    [action.name]: action.value
                }
            })
        case 'RESET_COUNTRY':
            return Object.assign({}, state, {
                country: {
                    name: '',
                    status: '',
                    code: '',
                    phonecode: '',
                    flag: '',
                    currencyname: '',
                    currencycode: '',
                    symbol: '',
                    id: ''
                },
            })
            case 'EMPTY_CATEGORY_FEES_SPINNER':
                return Object.assign({}, state, {
                    ViewSpinner: true
                })
            case 'CATEGORY_LIST_SPINNER':
                return Object.assign({}, state, {
                    ListSpinner: true
                })
        case 'LIST_COUNTRY':
            return Object.assign({}, state, {
                listcountry: action.payload,
                // total:action.total,
                    ListSpinner: false
            })
        case 'VIEW_COUNTRY':
            return Object.assign({}, state, {
                country: action.payload
            })
        default:
            return state;
    }
}
import CONSTANTS from "../common/constants";
const initialState = {
	account: {
		name: '',
		hotelid: '',
		email: '',
		location: '',
		role: '',
		status: '',
	},
	previleges:[],
	accountDetail: [],
	fields: {
		name: '',
		email: '',
		logo: '',
		image: '',
		logoSrc: '',
	}
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'ACCOUNT_DETAILS':
			return Object.assign({}, state, {
				account: action.payload,
				role: action.payload?.role,
				previleges: action.payload ? action.payload.previleges : CONSTANTS.previleges,
			})
		case 'PREVILEGE_DETAILS':
			return Object.assign({}, state, {
				previleges: action.payload.previleges
			})
		case 'SETTING_DETAIL':
			return Object.assign({}, state, {
				fields: action.payload
			})
		case 'UPDATE_DETAIL':
			return Object.assign({}, state, {
				fields: action.payload
			})
		case 'HANDLE_CHANGE':
			return Object.assign({}, state, {
				fields: {
					...state.fields,
					[action.name]: action.value
				}
			})
		default:
			return state;
	}
}
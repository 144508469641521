const initialState = {
    listusercategory: [],
    totelusercategories: 0,
    Usercategory: {
        name:"",
        usertype: '',
        sellertype:'',
        email:'',
        profile: '',
        cc: '',
        phonenumber:'',
        officeaddress:'',
        homeaddress:'',
        adharcard:"",
        pancard:"",
        drivinglicense:"",
        shoplicense:"",
        adharcardstatus:"",
        pancardstatus:"",
        drivinglicensestatus:"",
        shoplicensestatus:"",
        aadharreason:'',
        rejecteddate:'',


    },
    ViewSpinner: false,
    ListSpinner: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_USERCATEGORY_CHANGE':
            return Object.assign({}, state, {
                Usercategory: {
                    ...state.Usercategory,
                    [action.name]: action.value
                }
            })
        case 'RESET_USERCATEGORY_DETAIL':
            return Object.assign({}, state, {
                Usercategory: initialState.Usercategory,
            })
        case 'EMPTY_COUNTRY_FEES_SPINNER':
            return Object.assign({}, state, {
                ViewSpinner: true
            })
        case 'COUNTRY_LIST_SPINNER':
            return Object.assign({}, state, {
                ListSpinner: true
            })
        case 'LIST_USERCATEGORY_DETAIL':
            return {
                ...state,
                listusercategory: action.payload,
                totelusercategories: action.total,
                ListSpinner: false,
            }
        case 'VIEW_USERCATEGORY_DETAIL':
            return Object.assign({}, state, {
                Usercategory: action.payload,
                ViewSpinner: false,
            })

        case 'EMPTY_USERCATEGORY_DETAIL':
            return Object.assign({}, state, {
                Usercategory: {
                    name:"",
                    usertype: '',
                    sellertype:'',
                    email:'',
                    profile: '',
                    cc: '',
                    phonenumber:'',
                    officeaddress:'',
                    homeaddress:'',
                    adharcard:"",
                    pancard:"",
                    drivinglicense:"",
                    shoplicense:"",
                    adharcardstatus:"",
                    pancardstatus:"",
                    drivinglicensestatus:"",
                    shoplicensestatus:"",
                    aadharreason:'',
                },

            })

        default:
            return state;
    }
}
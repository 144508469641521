
import jsPDF from "jspdf";
import 'jspdf-autotable';

const ExportPdfComponent = (exportData, title, headers, objName, pdfName) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(18);
    const data = [];
    exportData.map((elt, i) => {
        let arr = [i + 1]
        for (let obj of objName) {
            arr.push(elt[obj] ? elt[obj] : '')
        }
        data.push(arr)
    });
    let content = {
        startY: 50,
        head: headers,
        headStyles: { fillColor: [243, 253, 232], textColor: [11, 119, 2], },
        body: data,
        styles: {
            minCellHeight: 30,
            valign: 'middle',
            lineWidth: 0.5,
        },
        margin: {
            left: 10,
            right: 10,
            bottom: 70,
        },
        autoPaging: "text",
    };
    doc.setTextColor(11, 119, 2);
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(pdfName + ".pdf")
}

export default ExportPdfComponent

const CONSTANTS = {};

CONSTANTS.previleges = [
    { "name": "MainCategory", "view": false, "add": false, "edit": false, "delete": false },
    { "name": "SubCategory", "view": false, "add": false, "edit": false, "delete": false },
    { "name": "UserCategory", "view": false, "add": false, "edit": false, "delete": false },
    { "name": "Currencies", "view": false, "add": false, "edit": false, "delete": false },
    { "name": "Countries", "view": false, "add": false, "edit": false, "delete": false },
    { "name": "Categories", "view": false, "add": false, "edit": false, "delete": false },
    { "name": "Post", "view": false, "add": false, "edit": false, "delete": false },
    { "name": 'Sellingrequest', "view": false, "add": false, "edit": false, "delete": false }
]

CONSTANTS.allprevileges = [
    { "name": "MainCategory", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "SubCategory", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Users", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Currencies", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Countries", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Categories", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Post", "view": true, "add": true, "edit": true, "delete": true },
    { "name": "Sellingrequest", "view": true, "add": true, }
]

export default CONSTANTS;

const initialState = {
    listselling: [],
    totelselling: 0,
    selling: {
        categoryname: '',
        subcategoryname: '',
        quantity:'' ,
        pricedetail:'' ,
        address:'' ,
        vechile: '',
        frontimage: '',
        backimage: '',
        leftimage: '',
        rightimage: '',
        liceneseimage:'',
        aadharimage:'',
        driverimage:'',
        shipments:'',
       

    },
    ViewSpinner: false,
    ListSpinner: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_SELLING_REQUEST_CHANGE':
            return Object.assign({}, state, {
                selling: {
                    ...state.selling,
                    [action.name]: action.value
                }
            })
        case 'RESET_SELLING_REQUEST_DETAIL':
            return Object.assign({}, state, {
                selling: initialState.selling,
            })
        case 'EMPTY_SELLING_REQUEST_FEES_SPINNER':
            return Object.assign({}, state, {
                ViewSpinner: true
            })
        case 'POST_SELLING_REQUEST_SPINNER':
            return Object.assign({}, state, {
                ListSpinner: true
            })
        case 'LIST_SELLING_REQUEST_DETAIL':
            return {
                ...state,
                listselling: action.payload,
                totelselling: action.total,
                ListSpinner: false,
            }
        case 'VIEW_SELLING_REQUEST_DETAIL':
            return Object.assign({}, state, {
                selling: action.payload,
                ViewSpinner: false,
            })

        case 'EMPTY_SELLING_REQUEST_DETAIL':
            return Object.assign({}, state, {
                selling: {
                    categoryname: '',
                    subcategoryname: '',
                    quantity:'' ,
                    pricedetail:'' ,
                    address:'' ,
                    vechile: '',
                    frontimage: '',
                    backimage: '',
                    leftimage: '',
                    rightimage: '',
                    liceneseimage:'',
                    aadharimage:'',
                    driverimage:'',
                    shipments:'',
                   
            
                },
            })

        default:
            return state;
    }
}
const initialState = {
    listCategory: [],
    totelCategories: 0,
    Category: {
        categoryname: '',
        image: '',
        description: '',
    },
    ViewSpinner: false,
    ListSpinner: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_MAINCATEGORY_CHANGE':
            return Object.assign({}, state, {
                Category: {
                    ...state.Category,
                    [action.name]: action.value
                }
            })
        case 'RESET_MAINCATEGORY_DETAIL':
            return Object.assign({}, state, {
                Category: initialState.Category,
            })
        case 'EMPTY_MAINCATEGORY_FEES_SPINNER':
            return Object.assign({}, state, {
                ViewSpinner: true
            })
        case 'MAINCATEGORY_LIST_SPINNER':
            return Object.assign({}, state, {
                ListSpinner: true
            })
        case 'LIST_MAINCATEGORY_DETAIL':
            return {
                ...state,
                listCategory: action.payload,
                totelCategories: action.total,
                ListSpinner: false,
            }
        case 'VIEW_MAINCATEGORY_DETAIL':
            return Object.assign({}, state, {
                Category: action.payload,
                ViewSpinner: false,
            })

        case 'EMPTY_MAINCATEGORY_DETAIL':
            return Object.assign({}, state, {
                Category: {
                    categoryname: '',
                    image: '',
                    description: '',
                },

            })

        default:
            return state;
    }
}
import { combineReducers } from 'redux';
import settings from './settings';
import account from './account';
import maincategory from "./maincategory";
import subcategory from './subcategory';
import currency from './currency';
import country from './country';
import users from './users';
import Categories from './categories';
import post from './post'
import sellingrequest from './sellingrequest'


export default combineReducers({
    settings,
    account,
    maincategory,
    subcategory,
    currency,
    country,
    users,
    Categories,
    post,
    sellingrequest
});